import React from "react"
import Layout from './../components/shared/Layout';
import AboutContent from './../components/about/AboutContent';

const AboutPage = () => (
  <Layout title="About">
      <AboutContent />
  </Layout>
)

export default AboutPage;
