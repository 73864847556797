import styled from 'styled-components';

export const About = styled.section`
    background-color: ${props => props.theme.greyColor };

    .container {

        padding-bottom: 2rem;

        .top-wrapper {
            display: flex;
            color: ${props => props.theme.darkColor };
            padding: 2rem 0 0;

            .right-content, .left-content {
                flex: 1;
                padding: 0.5rem 1.6rem;
            }

            .left-content {

                h2{
                    font-size: 2rem;
                }

                h2 + span {
                    color: ${props => props.theme.secondaryColor };
                }

                .about-content-box {

                    padding: 1rem 0 0;

                    p {
                        padding-bottom: 0.3rem;
                        font-weight: 300;
                    }

                    a {
                        color: ${props => props.theme.darkColor };
                        text-decoration: underline;
                    }
                }

            }

            .right-content {

                background-size: cover;
                background-position: top center;
                border-radius: 0.8rem;
                position: relative;
                margin: 0.5rem 1.6rem 0.5rem 0.8rem;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: ${ props => props.theme.darkColor };
                    opacity: 0.5;
                    z-index: 1;
                    overflow: hidden;
                    border-radius: 0.8rem;
                }

            }

        }

        .facts {

            display: flex;
            padding: 1rem 1.6rem 0;

            .achievement {
                flex: 1;
                padding: 2rem 1rem;
                background-color: ${props => props.theme.lightColor };
                text-align: center;
                margin-left: 0.5rem;

                svg {
                    font-size: 1.5rem;
                    color: ${props => props.theme.darkColor };
                }

                h3 {
                    margin: 0.5rem 0;
                    font-size: 2rem;
                }

                p {
                    font-weight: 300;
                }
            }

        }

    }

    @media (max-width: 768px){

        .container {

            .top-wrapper {

                flex-direction: column;
                height: 60rem;

                .right-content {
                    flex: 2;
                }

            }

            .facts {
                flex-wrap: wrap;

                .achievement {
                    flex: 45%;
                    margin-bottom: 0.5rem;
                }
            }
        }

    }

    @media (max-width: 500px){

        .container {

            .top-wrapper {

                .left-content {

                    h2 {
                        font-size: 1.7rem;
                    }

                }

            }

            .facts {
                flex-direction: column;
                flex-wrap: nowrap;
                padding: 0.8rem;
            }
        }

    }

`