import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Stack } from './../../elements/SharedElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons'

const TechStack = (props) => {

    const tech = useStaticQuery(graphql`
    
        query tech {
            allContentfulAsset(filter: {
                title: {
                    eq: "tech-stack"
                }
            }){
                edges {
                    node {
                        file {
                            url
                            fileName
                        }
                    }
                }
            }
        }
    
    `)

    return (
        <Stack>
            <div className="container">
                <div className="tech-stack">
                    { props.icon ? <h2><FontAwesomeIcon icon={faCode} /> Tech <span className="effect">Stack</span></h2>: <h2>Tech <span className="effect">Stack</span></h2>}
                    <div className="tech-stack-container">
                        {
                            tech.allContentfulAsset.edges.map((edge) => {
                                return (
                                    <div className="tech-item" key={edge.node.file.fileName}>
                                        <img src={edge.node.file.url} alt="Prince Rathupa Tech Stack" />
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </Stack>
    )

}

export default TechStack;